import React from 'react';
import { motion } from 'framer-motion';
import Hero from '../components/Hero';
import FeaturedSitters from '../components/FeaturedSitters';
import InfoTabs from '../components/InfoTabs';
import FAQ from '../components/FAQ';
import BlogSection from '../components/BlogSection';

export default function HomePage() {
  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Hero />
      <section className="py-8 sm:py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <FeaturedSitters />
        </div>
      </section>
      <InfoTabs />
      <BlogSection />
      <FAQ />
    </motion.main>
  );
}