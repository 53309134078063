import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';

export type ProfileStep = 'profile' | 'services' | 'specificities' | 'availability';

const initialFormData = {
  profile: {
    name: '',
    phone: '',
    businessType: 'individual',
    businessName: '',
    address: '',
    coordinates: null,
    description: '',
    avatar: '',
    photos: [],
    tvaNumber: '',
    siretNumber: ''
  },
  services: {
    animals: [],
    services: [],
    serviceOptions: {
      hebergementChien: [],
      visiteChien: [],
      promenadeChien: [],
      hebergementChat: [],
      visiteChat: [],
      gardeChien: [],
      gardeChat: []
    }
  },
  specificities: {
    housingType: '',
    children: '',
    walkingAreas: [],
    experience: [],
    yearsOfExperience: ''
  },
  availability: {
    general: [],
    blockedDates: []
  }
};

export function useProfileSteps() {
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState<ProfileStep>('profile');
  const [formData, setFormData] = useState(initialFormData);

  // Sauvegarder les données dans le localStorage
  useEffect(() => {
    if (user) {
      localStorage.setItem(`profile-data-${user.uid}`, JSON.stringify(formData));
    }
  }, [formData, user]);

  const updateFormData = (
    step: ProfileStep,
    data: Partial<typeof formData[typeof step]>
  ) => {
    setFormData(prev => ({
      ...prev,
      [step]: { ...prev[step], ...data }
    }));
  };

  const nextStep = () => {
    if (currentStep === 'profile') setCurrentStep('services');
    else if (currentStep === 'services') setCurrentStep('specificities');
    else if (currentStep === 'specificities') setCurrentStep('availability');
  };

  const previousStep = () => {
    if (currentStep === 'services') setCurrentStep('profile');
    else if (currentStep === 'specificities') setCurrentStep('services');
    else if (currentStep === 'availability') setCurrentStep('specificities');
  };

  return {
    currentStep,
    setCurrentStep,
    formData,
    setFormData,
    updateFormData,
    nextStep,
    previousStep,
    isLastStep: currentStep === 'availability',
    isFirstStep: currentStep === 'profile'
  };
}