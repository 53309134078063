import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export default function NavLinks() {
  const { userData } = useAuth();

  return (
    <div className="hidden md:flex items-center space-x-8">
      {(!userData || userData?.role !== 'sitter') && (
        <Link to="/recherche" className="text-gray-600 hover:text-gray-900">
          Rechercher un PetSitter
        </Link>
      )}
      <Link to="/a-propos" className="text-gray-600 hover:text-gray-900">
        À propos
      </Link>
      <Link to="/comment-ca-marche" className="text-gray-600 hover:text-gray-900">
        Comment ça marche
      </Link>
      <Link to="/blog" className="text-gray-600 hover:text-gray-900">
        Blog
      </Link>
      <Link to="/contact" className="text-gray-600 hover:text-gray-900">
        Contact
      </Link>
    </div>
  );
}