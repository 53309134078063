import React from 'react';
import type { Service } from '../types';
import type { PetData } from './PetForm';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { SERVICE_OPTIONS } from '../data/serviceOptions';

interface ReservationConfirmationProps {
  petData: PetData | null;
  selectedService: Service | null;
  selectedOption: string;
  startDate: string;
  endDate: string;
  phoneNumber: string;
  formatPriceDetails: () => string;
  isPriceToDiscuss: () => boolean;
  getDuration: () => number;
  error: string | null;
  loading: boolean;
  onSubmit: () => void;
}

export default function ReservationConfirmation({
  petData,
  selectedService,
  selectedOption,
  startDate,
  endDate,
  phoneNumber,
  formatPriceDetails,
  isPriceToDiscuss,
  getDuration,
  error,
  loading,
  onSubmit
}: ReservationConfirmationProps) {
  return (
    <div className="space-y-6">
      <h3 className="font-medium mb-4">Confirmation de la réservation</h3>
      
      <div className="bg-gray-50 rounded-lg p-6 space-y-4">
        <div className="space-y-2">
          <h4 className="font-medium">Détails de l'animal</h4>
          <p>Nom : {petData?.name}</p>
          <p>Type : {petData?.type}</p>
          <p>Âge : {petData?.age} ans</p>
          {petData?.healthInfo && (
            <p>Informations de santé : {petData.healthInfo}</p>
          )}
        </div>

        <div className="border-t pt-4 space-y-2">
          <h4 className="font-medium">Détails de la réservation</h4>
          <p>Service : {selectedService?.description}</p>
          {selectedOption && (
            <p>Option choisie : {SERVICE_OPTIONS[selectedOption] || selectedOption}</p>
          )}
          <p>Du : {format(new Date(startDate), 'dd MMMM yyyy', { locale: fr })}</p>
          <p>Au : {format(new Date(endDate), 'dd MMMM yyyy', { locale: fr })}</p>
          <p>Téléphone : {phoneNumber}</p>
          <div className="text-sm text-gray-600">{formatPriceDetails()}</div>
          <p className="font-bold">
            Total : {isPriceToDiscuss() ? 'Prix à discuter' : `${selectedService?.price * getDuration()}€`}
          </p>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg">
          {error}
        </div>
      )}

      <button
        onClick={onSubmit}
        disabled={loading}
        className="w-full bg-primary-500 text-white px-6 py-3 rounded-lg hover:bg-primary-600 transition disabled:opacity-50"
      >
        {loading ? 'Envoi en cours...' : 'Confirmer la réservation'}
      </button>
    </div>
  );
}