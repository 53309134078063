import { doc, updateDoc, deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { db } from '../config/firebase';

export async function handleAccountSuspension(userId: string, currentSuspendedState: boolean) {
  try {
    // Mettre à jour le profil utilisateur
    await updateDoc(doc(db, 'users', userId), {
      suspended: !currentSuspendedState
    });

    // Mettre à jour le profil pet sitter
    await updateDoc(doc(db, 'sitterProfiles', userId), {
      suspended: !currentSuspendedState
    });

    return !currentSuspendedState;
  } catch (err) {
    throw new Error('Erreur lors de la modification du statut du compte');
  }
}

export async function handleAccountDeletion(user: any, password: string, logout: () => Promise<void>) {
  try {
    // Re-authenticate before deletion
    const credential = EmailAuthProvider.credential(
      user.email!,
      password
    );
    await reauthenticateWithCredential(user, credential);

    // Delete all associated data
    await deleteUserData(user.uid);

    // Delete Firebase Auth account
    await user.delete();

    // Logout
    await logout();

    return true;
  } catch (err) {
    throw new Error('Une erreur est survenue lors de la suppression du compte');
  }
}

async function deleteUserData(userId: string) {
  const collections = ['reservations', 'reviews', 'notifications', 'favorites'];
  
  for (const collectionName of collections) {
    const q = query(
      collection(db, collectionName),
      where('userId', '==', userId)
    );
    const snapshot = await getDocs(q);
    const deletions = snapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletions);
  }

  // Delete profile documents
  await deleteDoc(doc(db, 'sitterProfiles', userId));
  await deleteDoc(doc(db, 'users', userId));
}