import { useState } from 'react';
import { useAuth } from './useAuth';

export function usePetSitterProfile(sitterId: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { updateUserProfile } = useAuth();

  const updateProfile = async (profileData: any) => {
    try {
      setLoading(true);
      setError(null);

      await updateUserProfile(profileData);

      return profileData;
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Erreur lors de la mise à jour du profil');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    updateProfile
  };
}