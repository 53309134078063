import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import LoadingSpinner from '../components/LoadingSpinner';
import { ROUTES } from './constants';

interface PrivateRouteProps {
  children: React.ReactNode;
  allowedRole: 'sitter' | 'owner';
}

export function PrivateRoute({ children, allowedRole }: PrivateRouteProps) {
  const { user, userData, loading, initialized } = useAuth();
  const location = useLocation();

  if (loading || !initialized) {
    return <LoadingSpinner />;
  }

  if (!user || !userData) {
    return <Navigate to={ROUTES.AUTH} state={{ from: location.pathname }} />;
  }

  if (userData.role !== allowedRole) {
    return <Navigate to={ROUTES.HOME} />;
  }

  return <>{children}</>;
}