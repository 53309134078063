import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Map, { Marker, Popup } from 'react-map-gl';
import { MapPin, Star, Clock, ExternalLink, Euro } from 'lucide-react';
import type { PetSitter } from '../types';

interface SearchMapProps {
  sitters: PetSitter[];
  userLocation?: { latitude: number; longitude: number } | null;
}

export default function SearchMap({ sitters, userLocation }: SearchMapProps) {
  const [selectedSitter, setSelectedSitter] = useState<PetSitter | null>(null);
  const [viewport, setViewport] = useState({
    latitude: 48.8566,
    longitude: 2.3522,
    zoom: 11
  });

  useEffect(() => {
    if (userLocation) {
      setViewport({
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
        zoom: 11
      });
    } else if (sitters.length > 0 && sitters[0].coordinates) {
      setViewport({
        latitude: sitters[0].coordinates.latitude,
        longitude: sitters[0].coordinates.longitude,
        zoom: 11
      });
    }
  }, [userLocation, sitters]);

  return (
    <div className="w-full h-[300px] rounded-lg overflow-hidden shadow-lg">
      <Map
        {...viewport}
        onMove={evt => setViewport(evt.viewState)}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxAccessToken="pk.eyJ1IjoiYnVkYTM2NSIsImEiOiJjbTE2anNkbnYwamxmMmpzODJwZXB3MWFiIn0.SyA_0s1DscP2AN9jH4FaaQ"
        style={{ width: '100%', height: '100%' }}
      >
        {userLocation && (
          <Marker
            latitude={userLocation.latitude}
            longitude={userLocation.longitude}
          >
            <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white border-2 border-white shadow-lg">
              <MapPin className="w-5 h-5" />
            </div>
          </Marker>
        )}

        {sitters.map(sitter => (
          sitter.coordinates && (
            <div key={sitter.id}>
              <Marker
                latitude={sitter.coordinates.latitude}
                longitude={sitter.coordinates.longitude}
                onClick={e => {
                  e.originalEvent.stopPropagation();
                  setSelectedSitter(sitter);
                }}
              >
                <button className="w-8 h-8 bg-primary-500 rounded-full flex items-center justify-center text-white hover:bg-primary-600 transition-colors transform hover:scale-110 shadow-lg">
                  <MapPin className="w-5 h-5" />
                </button>
              </Marker>

              {selectedSitter?.id === sitter.id && sitter.coordinates && (
                <Popup
                  longitude={sitter.coordinates.longitude}
                  latitude={sitter.coordinates.latitude}
                  anchor="bottom"
                  onClose={() => setSelectedSitter(null)}
                  closeButton={true}
                  closeOnClick={false}
                  className="rounded-lg overflow-hidden !max-w-[280px]"
                >
                  <div className="p-0">
                    <div className="relative h-24 w-full">
                      <img
                        src={sitter.avatar || `https://source.unsplash.com/400x300/?pet,dog,cat&random=${sitter.id}`}
                        alt={sitter.name}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                      <div className="absolute bottom-0 left-0 p-2">
                        <h3 className="font-bold text-base text-white">{sitter.name}</h3>
                        <div className="flex items-center text-xs text-white/90">
                          <MapPin className="w-3 h-3 mr-1" />
                          {sitter.location}
                        </div>
                      </div>
                    </div>

                    <div className="p-3 space-y-2">
                      <div className="flex items-center justify-between text-sm">
                        <div className="flex items-center">
                          <Star className="w-4 h-4 text-yellow-400 fill-current" />
                          <span className="ml-1">{sitter.rating.toFixed(1)}</span>
                        </div>
                        <div className="flex items-center text-primary-500 text-xs">
                          <Clock className="w-3 h-3 mr-1" />
                          <span>Répond rapidement</span>
                        </div>
                      </div>

                      <div className="space-y-1">
                        <h4 className="text-sm font-medium text-gray-900">Services :</h4>
                        <div className="flex flex-wrap gap-1">
                          {sitter.services.map(service => (
                            <span
                              key={service.type}
                              className="px-2 py-0.5 bg-primary-50 text-primary-700 rounded-full text-xs"
                            >
                              {service.type}
                            </span>
                          ))}
                        </div>
                      </div>

                      <div className="flex items-center justify-between text-xs pt-1">
                        <div className="flex items-center text-gray-600">
                          <Euro className="w-3 h-3 mr-1" />
                          À partir de {Math.min(...sitter.services.map(s => s.price))}€/jour
                        </div>
                      </div>

                      <Link
                        to={`/petsitter/${sitter.id}`}
                        className="mt-2 flex items-center justify-center w-full bg-primary-500 text-white px-3 py-1.5 rounded-lg hover:bg-primary-600 transition text-sm"
                      >
                        <ExternalLink className="w-3 h-3 mr-1" />
                        Voir le profil
                      </Link>
                    </div>
                  </div>
                </Popup>
              )}
            </div>
          )
        ))}
      </Map>
    </div>
  );
}