import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { AppLayout } from './layouts/AppLayout';
import { AppRoutes } from './routes/AppRoutes';
import { AuthProvider } from './contexts/AuthContext';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <ScrollToTop />
        <AnimatePresence mode="wait">
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          </div>
        </AnimatePresence>
      </Router>
    </AuthProvider>
  );
}