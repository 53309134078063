import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useReservations } from '../hooks/useReservations';
import type { PetSitter, Service } from '../types';
import Calendar from './Calendar';
import PetForm, { PetData } from './PetForm';
import { format, addHours, differenceInDays } from 'date-fns';
import { fr } from 'date-fns/locale';
import ReservationSteps from './ReservationSteps';
import ReservationConfirmation from './ReservationConfirmation';

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  sitter: PetSitter;
}

type Step = 'pet' | 'service' | 'dates' | 'confirmation';

export default function ReservationModal({
  isOpen,
  onClose,
  sitter
}: ReservationModalProps) {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const { createReservation } = useReservations();
  const [currentStep, setCurrentStep] = useState<Step>('pet');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [petData, setPetData] = useState<PetData | null>(null);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  if (!isOpen) return null;

  const handlePetSubmit = (data: PetData) => {
    if (!phoneNumber.trim()) {
      alert("Veuillez entrer votre numéro de téléphone");
      return;
    }
    setPetData(data);
    setCurrentStep('service');
  };

  const handleDateSelect = (date: Date) => {
    const adjustedDate = addHours(date, 2);
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd');
    
    if (!startDate || (startDate && endDate)) {
      setStartDate(formattedDate);
      setEndDate('');
    } else if (formattedDate > startDate) {
      setEndDate(formattedDate);
    } else {
      setStartDate(formattedDate);
      setEndDate('');
    }
  };

  const getDuration = () => {
    if (!startDate || !endDate) return 0;
    return differenceInDays(new Date(endDate), new Date(startDate)) + 1;
  };

  const isPriceToDiscuss = () => {
    const duration = getDuration();
    return duration > 7;
  };

  const formatPriceDetails = () => {
    if (!selectedService || !startDate || !endDate) return '';
    const duration = getDuration();
    
    if (duration > 7) {
      return `${duration} jours - Prix à discuter avec le pet sitter`;
    }

    return `${duration} jour${duration > 1 ? 's' : ''} × ${selectedService.price}€`;
  };

  const handleSubmit = async () => {
    if (!user || !selectedService || !startDate || !endDate || !petData || !phoneNumber) return;

    setLoading(true);
    setError(null);

    try {
      const duration = getDuration();
      await createReservation({
        sitterId: sitter.id,
        ownerId: user.uid,
        ownerName: user.displayName || 'Anonyme',
        ownerPhone: phoneNumber,
        startDate,
        endDate,
        serviceType: selectedService.type,
        serviceOption: selectedOption,
        totalPrice: duration > 7 ? 0 : selectedService.price * duration,
        duration,
        status: 'pending',
        petInfo: {
          ...petData,
          age: parseInt(petData.age) || 0
        },
        priceToDiscuss: duration > 7
      });

      onClose();
      alert('Votre demande de réservation a été envoyée avec succès !');
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <div>
            <h2 className="text-xl font-semibold">Réserver avec {sitter.name}</h2>
            <p className="text-sm text-gray-500 mt-1">
              {currentStep === 'pet' && "Informations sur votre animal"}
              {currentStep === 'service' && "Choix du service"}
              {currentStep === 'dates' && "Sélection des dates"}
              {currentStep === 'confirmation' && "Confirmation"}
            </p>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="p-4">
          {currentStep !== 'confirmation' ? (
            <ReservationSteps
              currentStep={currentStep}
              petData={petData}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              onPetSubmit={handlePetSubmit}
              selectedService={selectedService}
              setSelectedService={setSelectedService}
              setCurrentStep={setCurrentStep}
              sitter={sitter}
              startDate={startDate}
              endDate={endDate}
              onDateSelect={handleDateSelect}
              formatPriceDetails={formatPriceDetails}
              getDuration={getDuration}
              isPriceToDiscuss={isPriceToDiscuss}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          ) : (
            <ReservationConfirmation
              petData={petData}
              selectedService={selectedService}
              selectedOption={selectedOption}
              startDate={startDate}
              endDate={endDate}
              phoneNumber={phoneNumber}
              formatPriceDetails={formatPriceDetails}
              isPriceToDiscuss={isPriceToDiscuss}
              getDuration={getDuration}
              error={error}
              loading={loading}
              onSubmit={handleSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
}