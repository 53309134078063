import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { Lock, AlertTriangle, Trash2, PauseCircle, PlayCircle } from 'lucide-react';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import PasswordInput from '../../components/PasswordInput';
import { handleAccountDeletion, handleAccountSuspension } from '../../services/accountService';
import { loadSuspensionState } from '../../utils/auth';

export default function SettingsPage() {
  const navigate = useNavigate();
  const { user, userData, logout } = useAuth();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [accountSuspended, setAccountSuspended] = useState(false);
  const [deletionLoading, setDeletionLoading] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');

  useEffect(() => {
    const initSuspensionState = async () => {
      if (!user) return;
      const suspended = await loadSuspensionState(user.uid);
      setAccountSuspended(suspended);
    };

    initSuspensionState();
  }, [user]);

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    if (newPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const credential = EmailAuthProvider.credential(
        user.email!,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      
      setSuccess('Mot de passe modifié avec succès');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err) {
      setError('Erreur lors du changement de mot de passe. Vérifiez votre mot de passe actuel.');
    } finally {
      setLoading(false);
    }
  };

  const handleSuspendAccount = async () => {
    if (!user) return;

    try {
      const newSuspendedState = await handleAccountSuspension(user.uid, accountSuspended);
      setAccountSuspended(newSuspendedState);
      setSuccess(accountSuspended ? 'Compte réactivé' : 'Compte suspendu');
    } catch (err) {
      setError('Erreur lors de la modification du statut du compte');
    }
  };

  const handleDeleteAccount = async () => {
    if (!user || !showDeleteConfirm || deletionLoading || !deletePassword) return;

    try {
      setDeletionLoading(true);
      setError(null);

      await handleAccountDeletion(user, deletePassword, logout);
      navigate('/');
    } catch (err) {
      setError('Une erreur est survenue. Veuillez vérifier votre mot de passe et réessayer.');
    } finally {
      setDeletionLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-2xl font-bold mb-8">Paramètres du compte</h1>

        {/* Changement de mot de passe */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-lg font-medium mb-4 flex items-center">
            <Lock className="w-5 h-5 mr-2 text-gray-500" />
            Changer le mot de passe
          </h2>

          <form onSubmit={handlePasswordChange} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Mot de passe actuel
              </label>
              <PasswordInput
                value={currentPassword}
                onChange={setCurrentPassword}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nouveau mot de passe
              </label>
              <PasswordInput
                value={newPassword}
                onChange={setNewPassword}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Confirmer le nouveau mot de passe
              </label>
              <PasswordInput
                value={confirmPassword}
                onChange={setConfirmPassword}
              />
            </div>

            {error && (
              <div className="bg-red-50 text-red-700 p-3 rounded-lg text-sm">
                {error}
              </div>
            )}

            {success && (
              <div className="bg-green-50 text-green-700 p-3 rounded-lg text-sm">
                {success}
              </div>
            )}

            <button
              type="submit"
              disabled={loading || !currentPassword || !newPassword || !confirmPassword}
              className="w-full bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 transition disabled:opacity-50"
            >
              {loading ? 'Chargement...' : 'Changer le mot de passe'}
            </button>
          </form>
        </div>

        {/* Suspension du compte */}
        <div className="bg-white rounded-lg shadow p-6 mb-6">
          <h2 className="text-lg font-medium mb-4 flex items-center">
            {accountSuspended ? (
              <PlayCircle className="w-5 h-5 mr-2 text-gray-500" />
            ) : (
              <PauseCircle className="w-5 h-5 mr-2 text-gray-500" />
            )}
            {accountSuspended ? 'Réactiver le compte' : 'Suspendre le compte'}
          </h2>

          <p className="text-gray-600 text-sm mb-4">
            {accountSuspended 
              ? 'Votre compte est actuellement suspendu. Les propriétaires ne peuvent pas voir votre profil ni faire de réservations.'
              : 'La suspension masque temporairement votre profil. Vous pourrez le réactiver à tout moment.'}
          </p>

          <button
            onClick={handleSuspendAccount}
            className={`w-full px-4 py-2 rounded-lg transition ${
              accountSuspended
                ? 'bg-green-500 text-white hover:bg-green-600'
                : 'bg-yellow-500 text-white hover:bg-yellow-600'
            }`}
          >
            {accountSuspended ? 'Réactiver le compte' : 'Suspendre le compte'}
          </button>
        </div>

        {/* Suppression du compte */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-lg font-medium mb-4 flex items-center text-red-600">
            <Trash2 className="w-5 h-5 mr-2" />
            Supprimer le compte
          </h2>

          <div className="bg-red-50 p-4 rounded-lg mb-4">
            <div className="flex">
              <AlertTriangle className="w-5 h-5 text-red-400 mr-2" />
              <div>
                <h3 className="text-sm font-medium text-red-800">Attention</h3>
                <p className="text-sm text-red-700 mt-1">
                  La suppression de votre compte est irréversible. Toutes vos données seront définitivement effacées, y compris :
                </p>
                <ul className="list-disc list-inside mt-2 text-sm text-red-700">
                  <li>Votre profil et informations personnelles</li>
                  <li>Vos réservations passées et en cours</li>
                  <li>Vos avis et évaluations</li>
                  <li>Toutes vos notifications</li>
                </ul>
              </div>
            </div>
          </div>

          {!showDeleteConfirm ? (
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="w-full bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition"
            >
              Supprimer le compte
            </button>
          ) : (
            <div className="space-y-4">
              <p className="text-sm text-gray-600">
                Pour confirmer la suppression, veuillez entrer votre mot de passe :
              </p>
              <div>
                <PasswordInput
                  value={deletePassword}
                  onChange={setDeletePassword}
                  placeholder="Votre mot de passe"
                />
              </div>
              {error && (
                <div className="bg-red-50 text-red-700 p-3 rounded-lg text-sm">
                  {error}
                </div>
              )}
              <div className="flex space-x-4">
                <button
                  onClick={() => {
                    setShowDeleteConfirm(false);
                    setDeletePassword('');
                    setError(null);
                  }}
                  className="flex-1 bg-gray-100 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-200 transition"
                >
                  Annuler
                </button>
                <button
                  onClick={handleDeleteAccount}
                  disabled={deletionLoading || !deletePassword}
                  className="flex-1 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition disabled:opacity-50"
                >
                  {deletionLoading ? 'Suppression en cours...' : 'Confirmer la suppression'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}