import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { usePetSitterProfile } from '../../hooks/usePetSitterProfile';
import { useProfileData } from '../../hooks/useProfileData';
import { useProfileSteps } from '../../hooks/useProfileSteps';
import { motion } from 'framer-motion';
import { Save, ChevronRight, User, Briefcase, Settings, Calendar } from 'lucide-react';
import ProfileContent from '../../components/profile/ProfileContent';
import ServicesContent from '../../components/profile/ServicesContent';
import SpecificitiesContent from '../../components/profile/SpecificitiesContent';
import AvailabilityContent from '../../components/profile/AvailabilityContent';
import LoadingSpinner from '../../components/LoadingSpinner';
import SubscriptionAlert from '../../components/SubscriptionAlert';

const TABS = [
  { id: 'profile', label: 'Profil', icon: User },
  { id: 'services', label: 'Services', icon: Briefcase },
  { id: 'specificities', label: 'Spécificités', icon: Settings },
  { id: 'availability', label: 'Indisponibilités', icon: Calendar }
];

export default function PetSitterProfilePage() {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const { updateProfile } = usePetSitterProfile(user?.uid || '');
  const { profileData, loading: dataLoading } = useProfileData();
  const {
    currentStep,
    formData,
    updateFormData,
    setFormData,
    nextStep,
    previousStep,
    isLastStep,
    isFirstStep,
    setCurrentStep
  } = useProfileSteps();

  const [submitting, setSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (profileData) {
      setFormData(profileData);
    }
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      const hasChanged = JSON.stringify(formData) !== JSON.stringify(profileData);
      setHasChanges(hasChanged);
    }
  }, [formData, profileData]);

  const handleSave = async () => {
    setSubmitting(true);
    try {
      await updateProfile({
        ...formData.profile,
        services: formData.services.services,
        animals: formData.services.animals,
        serviceOptions: formData.services.serviceOptions,
        availability: formData.availability.general,
        blockedDates: formData.availability.blockedDates,
        location: formData.profile.address,
        coordinates: formData.profile.coordinates,
        specificities: formData.specificities
      });
      setHasChanges(false);
      alert('Modifications enregistrées avec succès !');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      alert('Une erreur est survenue lors de l\'enregistrement des modifications.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    if (!isLastStep) {
      nextStep();
      return;
    }

    setSubmitting(true);
    try {
      await updateProfile({
        ...formData.profile,
        services: formData.services.services,
        animals: formData.services.animals,
        serviceOptions: formData.services.serviceOptions,
        availability: formData.availability.general,
        blockedDates: formData.availability.blockedDates,
        location: formData.profile.address,
        coordinates: formData.profile.coordinates,
        specificities: formData.specificities
      });
      alert('Votre profil a été publié avec succès !');
      navigate('/');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      alert('Une erreur est survenue lors de la publication de votre profil.');
    } finally {
      setSubmitting(false);
    }
  };

  if (dataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-primary-500 text-white p-4 sm:p-6">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-xl sm:text-2xl font-bold mb-2">Configuration de votre profil</h1>
          <p className="text-sm sm:text-base opacity-90">
            Remplissez avec soin le formulaire ci-dessous afin de permettre aux maîtres de trouver aisément le PetSitter répondant au mieux à leurs désiderata.
          </p>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 py-4">
        <SubscriptionAlert />

        {/* Navigation tabs */}
        <div className="mb-6">
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
            {TABS.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setCurrentStep(tab.id as any)}
                  className={`flex flex-col items-center justify-center p-3 rounded-lg transition-colors ${
                    currentStep === tab.id
                      ? 'bg-primary-500 text-white'
                      : 'bg-white text-gray-600 hover:bg-gray-50'
                  }`}
                >
                  <Icon className="w-5 h-5 mb-1" />
                  <span className="text-sm font-medium">{tab.label}</span>
                </button>
              );
            })}
          </div>
        </div>

        {/* Main content */}
        <motion.div 
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.2 }}
          className="bg-white rounded-xl shadow-lg overflow-hidden"
        >
          <div className="p-4 sm:p-6">
            {currentStep === 'profile' && (
              <ProfileContent formData={formData} updateFormData={updateFormData} />
            )}
            {currentStep === 'services' && (
              <ServicesContent formData={formData} updateFormData={updateFormData} />
            )}
            {currentStep === 'specificities' && (
              <SpecificitiesContent formData={formData} updateFormData={updateFormData} />
            )}
            {currentStep === 'availability' && (
              <AvailabilityContent formData={formData} updateFormData={updateFormData} />
            )}
          </div>

          {/* Navigation buttons */}
          <div className="bg-gray-50 px-4 sm:px-6 py-4">
            <div className="flex flex-col sm:flex-row items-center gap-4">
              {!isFirstStep && (
                <button
                  onClick={previousStep}
                  className="w-full sm:w-auto text-gray-600 hover:text-gray-900 text-sm py-2"
                >
                  Retour
                </button>
              )}
              <div className="flex items-center gap-4 w-full sm:w-auto sm:ml-auto">
                {hasChanges && (
                  <button
                    onClick={handleSave}
                    disabled={submitting}
                    className="flex-1 sm:flex-none flex items-center justify-center px-4 py-2 bg-red-100 text-red-700 rounded-lg hover:bg-red-200 transition-colors text-sm"
                  >
                    <Save className="w-4 h-4 mr-2" />
                    {submitting ? 'Enregistrement...' : 'Enregistrer'}
                  </button>
                )}
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleSubmit}
                  disabled={submitting}
                  className="flex-1 sm:flex-none flex items-center justify-center bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition disabled:opacity-50 text-sm"
                >
                  <span>{submitting ? 'Chargement...' : (isLastStep ? 'Publier' : 'Suivant')}</span>
                  {!isLastStep && <ChevronRight className="w-4 h-4 ml-1" />}
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}