export const ANIMAL_LABELS: { [key: string]: string } = {
  'petit-chien': 'Petit Chien (-10kg)',
  'moyen-chien': 'Moyen chien (10-20kg)',
  'grand-chien': 'Grand chien (+20kg)',
  'chien-attaque': "Chien d'attaque (Cat. 1)",
  'chien-garde': 'Chien de garde (Cat. 2)',
  'chat': 'Chat',
  'lapin': 'Lapin',
  'rongeur': 'Petit rongeur',
  'oiseau': 'Oiseau',
  'volaille': 'Volaille',
  'nac': 'NAC (Nouveaux Animaux de Compagnie)'
};