import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from './useAuth';

export function useProfileData() {
  const { user } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadProfileData();
  }, [user]);

  const loadProfileData = async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    try {
      const docRef = doc(db, 'sitterProfiles', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        // Transformer les données pour correspondre à la structure du formulaire
        const formattedData = {
          profile: {
            name: data.name || '',
            phone: data.phone || '',
            businessType: data.businessType || 'individual',
            businessName: data.businessName || '',
            address: data.location || '',
            coordinates: data.coordinates || null,
            description: data.description || '',
            avatar: data.avatar || '',
            photos: data.photos || [],
            tvaNumber: data.tvaNumber || '',
            siretNumber: data.siretNumber || ''
          },
          services: {
            animals: data.animals || [],
            services: data.services || [],
            serviceOptions: data.serviceOptions || {
              hebergementChien: [],
              visiteChien: [],
              promenadeChien: [],
              hebergementChat: [],
              visiteChat: [],
              gardeChien: [],
              gardeChat: []
            }
          },
          specificities: {
            housingType: data.specificities?.housingType || '',
            children: data.specificities?.children || '',
            walkingAreas: data.specificities?.walkingAreas || [],
            experience: data.specificities?.experience || [],
            yearsOfExperience: data.specificities?.yearsOfExperience || ''
          },
          availability: {
            general: data.availability || [],
            blockedDates: data.blockedDates || []
          }
        };
        setProfileData(formattedData);
      }
      setLoading(false);
    } catch (err) {
      setError('Erreur lors du chargement des données du profil');
      setLoading(false);
    }
  };

  return { profileData, loading, error, loadProfileData };
}