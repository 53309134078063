import React, { useState } from 'react';
import { MapPin, PawPrint, Briefcase, Check, ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import type { FilterState } from '../pages/SearchPage';
import type { PetSitter } from '../types';
import SearchMap from './SearchMap';
import AddressAutocomplete from './AddressAutocomplete';

interface SearchFiltersProps {
  filters: FilterState;
  onChange: (filters: FilterState) => void;
  className?: string;
  sitters: PetSitter[];
}

const ANIMAL_OPTIONS = [
  { id: 'petit-chien', label: 'Petit Chien (-10kg)' },
  { id: 'moyen-chien', label: 'Moyen chien (10-20kg)' },
  { id: 'grand-chien', label: 'Grand chien (+20kg)' },
  { id: 'chien-attaque', label: "Chien d'attaque (Cat. 1)" },
  { id: 'chien-garde', label: 'Chien de garde (Cat. 2)' },
  { id: 'chat', label: 'Chat' }
];

const SERVICE_OPTIONS = [
  { id: 'hebergement', label: 'Hébergement (garde de +12h)' },
  { id: 'garde', label: 'Garde (-12h)' },
  { id: 'visite', label: 'Visite à domicile' },
  { id: 'promenade', label: 'Promenade' }
];

// Options conditionnelles pour les chiens
const DOG_HOUSING_OPTIONS = [
  { id: 'ext-espace-privatif', label: 'En extérieur dans un espace privatif clos (chenil)' },
  { id: 'partage-sans-acces', label: "Partage l'espace de vie sans accès extérieur" },
  { id: 'partage-avec-acces', label: "Partage l'espace de vie avec accès extérieur" },
  { id: 'int-sans-acces', label: 'En intérieur sans accès extérieur' },
  { id: 'int-avec-acces', label: 'En intérieur avec accès extérieur' }
];

const DOG_VISIT_OPTIONS = [
  { id: '1-visite', label: '1x visite à domicile par jour' },
  { id: '2-visites', label: '2x visites à domicile par jour' },
  { id: '1-visite-1-promenade', label: '1x visite + 1x promenade par jour' },
  { id: '2-visites-2-promenades', label: '2x visites + 2x promenades par jour' }
];

const DOG_WALK_OPTIONS = [
  { id: 'promenade-1x', label: '1x promenade par jour' },
  { id: 'promenade-2x', label: '2x promenades par jour' }
];

// Options conditionnelles pour les chats
const CAT_HOUSING_OPTIONS = [
  { id: 'chat-partage-sans-acces', label: "Partage l'espace de vie sans accès extérieur" },
  { id: 'chat-partage-avec-acces', label: "Partage l'espace de vie avec accès extérieur" },
  { id: 'chat-int-sans-acces', label: 'En intérieur sans accès extérieur' },
  { id: 'chat-int-avec-acces', label: 'En intérieur avec accès extérieur' }
];

const CAT_VISIT_OPTIONS = [
  { id: 'chat-1-visite', label: '1x visite à domicile par jour' },
  { id: 'chat-2-visites', label: '2x visites à domicile par jour' }
];

export default function SearchFilters({
  filters,
  onChange,
  className = '',
  sitters = []
}: SearchFiltersProps) {
  const [showOptions, setShowOptions] = useState(false);

  const handleLocationChange = (address: string, coordinates?: { latitude: number; longitude: number }) => {
    onChange({
      ...filters,
      location: address,
      coordinates: coordinates || null
    });
  };

  const getConditionalOptions = () => {
    const isDog = filters.animalType.includes('chien');
    const isCat = filters.animalType === 'chat';

    if (!filters.animalType || !filters.serviceType) return null;

    if (isDog) {
      switch (filters.serviceType) {
        case 'hebergement':
        case 'garde':
          return {
            title: "Type d'hébergement souhaité",
            options: DOG_HOUSING_OPTIONS,
            key: 'option1'
          };
        case 'visite':
          return {
            title: 'Fréquence des visites',
            options: DOG_VISIT_OPTIONS,
            key: 'option2'
          };
        case 'promenade':
          return {
            title: 'Fréquence des promenades',
            options: DOG_WALK_OPTIONS,
            key: 'option3'
          };
        default:
          return null;
      }
    }

    if (isCat) {
      switch (filters.serviceType) {
        case 'hebergement':
        case 'garde':
          return {
            title: "Type d'hébergement souhaité",
            options: CAT_HOUSING_OPTIONS,
            key: 'option4'
          };
        case 'visite':
          return {
            title: 'Fréquence des visites',
            options: CAT_VISIT_OPTIONS,
            key: 'option5'
          };
        default:
          return null;
      }
    }

    return null;
  };

  const conditionalOptions = getConditionalOptions();

  return (
    <motion.div 
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      className={`bg-white rounded-lg shadow-lg p-6 space-y-6 ${className}`}
    >
      <div>
        <h2 className="text-lg font-semibold mb-6">Critères de recherche</h2>

        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Localisation
            </label>
            <AddressAutocomplete
              value={filters.location}
              onChange={handleLocationChange}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type d'animal
            </label>
            <select
              value={filters.animalType}
              onChange={(e) => {
                onChange({
                  ...filters,
                  animalType: e.target.value,
                  serviceType: '',
                  options: {
                    option1: '',
                    option2: '',
                    option3: '',
                    option4: '',
                    option5: ''
                  }
                });
              }}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
            >
              <option value="">Sélectionnez un type d'animal</option>
              {ANIMAL_OPTIONS.map(option => (
                <option key={option.id} value={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {filters.animalType && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Type de service
              </label>
              <select
                value={filters.serviceType}
                onChange={(e) => {
                  onChange({
                    ...filters,
                    serviceType: e.target.value,
                    options: {
                      option1: '',
                      option2: '',
                      option3: '',
                      option4: '',
                      option5: ''
                    }
                  });
                }}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
              >
                <option value="">Sélectionnez un service</option>
                {SERVICE_OPTIONS.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          {conditionalOptions && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {conditionalOptions.title}
              </label>
              <select
                value={filters.options[conditionalOptions.key]}
                onChange={(e) => {
                  onChange({
                    ...filters,
                    options: {
                      ...filters.options,
                      [conditionalOptions.key]: e.target.value
                    }
                  });
                }}
                className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
              >
                <option value="">Sélectionnez une option</option>
                {conditionalOptions.options.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Rayon de recherche: {filters.radius} km
            </label>
            <input
              type="range"
              min="1"
              max="50"
              value={filters.radius}
              onChange={(e) => onChange({ ...filters, radius: parseInt(e.target.value) })}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-primary-500"
            />
            <div className="flex justify-between text-sm text-gray-600 mt-1">
              <span>1 km</span>
              <span>50 km</span>
            </div>
          </div>

          <motion.button
            onClick={() => onChange({
              animalType: '',
              serviceType: '',
              location: '',
              coordinates: null,
              radius: 10,
              options: {
                option1: '',
                option2: '',
                option3: '',
                option4: '',
                option5: ''
              }
            })}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="w-full px-4 py-2 text-sm text-primary-500 hover:bg-primary-50 rounded-lg transition border border-primary-500"
          >
            Réinitialiser les filtres
          </motion.button>

          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-700 mb-2">
              PetSitters disponibles
            </h3>
            <div className="h-[300px]">
              <SearchMap sitters={sitters} userLocation={filters.coordinates} />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}