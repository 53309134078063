import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import NotificationBell from '../NotificationBell';
import MobileMenu from './MobileMenu';
import UserMenu from './UserMenu';
import NavLinks from './NavLinks';

export default function Navbar() {
  const { user, userData, logout } = useAuth();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow-sm relative z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20 items-center">
          {/* Logo */}
          <div className="flex items-center space-x-4">
            <Link to="/" className="flex items-center">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/europetsitter-fd612.firebasestorage.app/o/logo%20Europetsitter.avif?alt=media&token=939040f2-c959-4b6b-8484-28128a62b145"
                alt="EuroPetSitter" 
                className="h-12 w-auto"
              />
              <span className="hidden md:block ml-3 font-bold text-xl text-gray-900">
                EuroPetSitter.fr
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <NavLinks />
            
            {user && userData ? (
              <div className="flex items-center space-x-4">
                <NotificationBell />
                <UserMenu />
              </div>
            ) : (
              <Link 
                to="/connexion"
                className="bg-primary-500 text-white px-4 py-2 rounded-full hover:bg-primary-600 transition"
              >
                Connexion
              </Link>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button 
              onClick={() => setIsMobileMenuOpen(true)}
              className="text-gray-600 hover:text-gray-900 p-2"
            >
              <div className="w-6 h-5 flex flex-col justify-between">
                <span className="block w-full h-0.5 bg-current"></span>
                <span className="block w-full h-0.5 bg-current"></span>
                <span className="block w-full h-0.5 bg-current"></span>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <MobileMenu 
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        user={user}
        userData={userData}
        onLogout={logout}
      />
    </nav>
  );
}