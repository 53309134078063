import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LoadingSpinner from '../components/LoadingSpinner';
import HomePage from '../pages/HomePage';
import SearchPage from '../pages/SearchPage';
import AboutPage from '../pages/AboutPage';
import HowItWorksPage from '../pages/HowItWorksPage';
import AuthPage from '../pages/AuthPage';
import SubscriptionPage from '../pages/SubscriptionPage';
import BlogPage from '../pages/BlogPage';
import ContactPage from '../pages/ContactPage';
import CookiesPage from '../pages/CookiesPage';
import TermsPage from '../pages/TermsPage';
import CoordinatesPage from '../pages/CoordinatesPage';
import PetSitterDetailsPage from '../pages/PetSitterDetailsPage';
import { PetSitterDashboard, PetSitterProfilePage, SettingsPage } from '../pages/petsitter';
import { OwnerDashboard, OwnerFavorites } from '../pages/owner';
import { PrivateRoute } from './PrivateRoute';
import { ROUTES } from './constants';

export function AppRoutes() {
  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <AnimatePresence mode="wait">
        <Routes>
          {/* Routes publiques */}
          <Route path={ROUTES.HOME} element={<HomePage />} />
          <Route path={ROUTES.SEARCH} element={<SearchPage />} />
          <Route path={ROUTES.ABOUT} element={<AboutPage />} />
          <Route path={ROUTES.HOW_IT_WORKS} element={<HowItWorksPage />} />
          <Route path={ROUTES.AUTH} element={<AuthPage />} />
          <Route path={ROUTES.SUBSCRIPTION} element={<SubscriptionPage />} />
          <Route path={ROUTES.BLOG} element={<BlogPage />} />
          <Route path={ROUTES.BLOG_POST} element={<BlogPage />} />
          <Route path={ROUTES.CONTACT} element={<ContactPage />} />
          <Route path={ROUTES.COOKIES} element={<CookiesPage />} />
          <Route path={ROUTES.TERMS} element={<TermsPage />} />
          <Route path={ROUTES.COORDINATES} element={<CoordinatesPage />} />
          <Route path={ROUTES.PETSITTER_DETAILS} element={<PetSitterDetailsPage />} />

          {/* Routes PetSitter */}
          <Route
            path={ROUTES.PETSITTER_DASHBOARD}
            element={
              <PrivateRoute allowedRole="sitter">
                <PetSitterDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.PETSITTER_PROFILE}
            element={
              <PrivateRoute allowedRole="sitter">
                <PetSitterProfilePage />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.PETSITTER_SETTINGS}
            element={
              <PrivateRoute allowedRole="sitter">
                <SettingsPage />
              </PrivateRoute>
            }
          />

          {/* Routes Propriétaire */}
          <Route
            path={ROUTES.OWNER_BOOKINGS}
            element={
              <PrivateRoute allowedRole="owner">
                <OwnerDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path={ROUTES.OWNER_FAVORITES}
            element={
              <PrivateRoute allowedRole="owner">
                <OwnerFavorites />
              </PrivateRoute>
            }
          />
        </Routes>
      </AnimatePresence>
    </React.Suspense>
  );
}