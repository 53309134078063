import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface PhotoGallerySliderProps {
  photos: string[];
  onClose?: () => void;
}

export default function PhotoGallerySlider({ photos, onClose }: PhotoGallerySliderProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);

  const showNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === photos.length - 1 ? 0 : prevIndex + 1
    );
  };

  const showPrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? photos.length - 1 : prevIndex - 1
    );
  };

  const handlePhotoClick = (photo: string) => {
    setSelectedPhoto(photo);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const handleModalNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedPhoto) {
      const currentIndex = photos.indexOf(selectedPhoto);
      const nextIndex = currentIndex === photos.length - 1 ? 0 : currentIndex + 1;
      setSelectedPhoto(photos[nextIndex]);
    }
  };

  const handleModalPrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedPhoto) {
      const currentIndex = photos.indexOf(selectedPhoto);
      const previousIndex = currentIndex === 0 ? photos.length - 1 : currentIndex - 1;
      setSelectedPhoto(photos[previousIndex]);
    }
  };

  if (photos.length === 0) return null;

  return (
    <>
      <div className="relative">
        <div className="grid grid-cols-2 gap-1 h-48">
          {photos.slice(0, 2).map((photo, index) => (
            <div key={index} className="relative h-48 overflow-hidden">
              <img
                src={photo}
                alt={`Photo ${index + 1}`}
                className="w-full h-full object-cover cursor-pointer hover:opacity-90 transition-opacity"
                onClick={() => handlePhotoClick(photo)}
              />
            </div>
          ))}
        </div>

        {photos.length > 2 && (
          <div 
            className="absolute bottom-4 right-4 bg-black/50 px-3 py-1.5 rounded-full cursor-pointer hover:bg-black/60 transition-colors"
            onClick={() => handlePhotoClick(photos[0])}
          >
            <span className="text-white text-sm font-medium">
              Voir les {photos.length} photos
            </span>
          </div>
        )}
      </div>

      {/* Fullscreen Modal */}
      <AnimatePresence>
        {selectedPhoto && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center"
            onClick={handleCloseModal}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors"
            >
              <X className="w-8 h-8" />
            </button>

            <button
              onClick={handleModalPrevious}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 rounded-full p-4 transition-colors"
            >
              <ChevronLeft className="w-8 h-8 text-white" />
            </button>

            <div className="max-w-7xl max-h-[90vh] px-16">
              <img
                src={selectedPhoto}
                alt="Photo en plein écran"
                className="max-w-full max-h-[90vh] object-contain"
                onClick={(e) => e.stopPropagation()}
              />
            </div>

            <button
              onClick={handleModalNext}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 rounded-full p-4 transition-colors"
            >
              <ChevronRight className="w-8 h-8 text-white" />
            </button>

            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/50 px-4 py-2 rounded-full">
              <span className="text-white">
                {photos.indexOf(selectedPhoto) + 1} / {photos.length}
              </span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}