import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserCircle, Settings, LogOut, Heart, Calendar, ChevronDown, Bell } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../hooks/useAuth';
import NotificationBell from '../NotificationBell';

export default function UserMenu() {
  const navigate = useNavigate();
  const { user, userData, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user || !userData) return null;

  const menuItems = userData.role === 'sitter' ? [
    {
      icon: Calendar,
      label: 'Réservation(s)',
      path: '/petsitter/tableau-de-bord',
      color: 'text-blue-600'
    },
    {
      icon: UserCircle,
      label: 'Configuration du profil',
      path: '/petsitter/profil',
      color: 'text-green-600'
    },
    {
      icon: Settings,
      label: 'Mot de passe et statut',
      path: '/petsitter/parametres',
      color: 'text-purple-600'
    }
  ] : [
    {
      icon: Calendar,
      label: 'Mes réservations',
      path: '/proprietaire/reservations',
      color: 'text-blue-600'
    },
    {
      icon: Heart,
      label: 'Mes favoris',
      path: '/proprietaire/favoris',
      color: 'text-red-600'
    }
  ];

  return (
    <div ref={menuRef} className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-gray-700 hover:text-gray-900 bg-white rounded-lg px-4 py-2 hover:bg-gray-50 transition-colors border"
      >
        <div className="w-8 h-8 rounded-full bg-primary-100 flex items-center justify-center text-primary-500">
          {user.displayName?.[0].toUpperCase() || 'U'}
        </div>
        <span className="hidden md:block font-medium">{user.displayName}</span>
        <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-72 bg-white rounded-lg shadow-lg py-2 z-50 border"
          >
            {/* Header */}
            <div className="px-4 py-3 border-b">
              <div className="font-medium">{user.displayName}</div>
              <div className="text-sm text-gray-500">{user.email}</div>
            </div>

            {/* Menu Items */}
            <div className="py-2">
              {menuItems.map((item, index) => (
                <motion.button
                  key={item.path}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  onClick={() => {
                    navigate(item.path);
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2 text-left hover:bg-gray-50 flex items-center space-x-3 group"
                >
                  <item.icon className={`w-5 h-5 ${item.color}`} />
                  <span className="text-gray-700 group-hover:text-gray-900">{item.label}</span>
                </motion.button>
              ))}
            </div>

            {/* Footer */}
            <div className="border-t py-2">
              <motion.button
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: menuItems.length * 0.1 }}
                onClick={() => {
                  logout();
                  setIsOpen(false);
                }}
                className="w-full px-4 py-2 text-left text-red-600 hover:bg-red-50 flex items-center space-x-3 group"
              >
                <LogOut className="w-5 h-5" />
                <span className="group-hover:text-red-700">Déconnexion</span>
              </motion.button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}