import React, { useState } from 'react';
import { Star, Heart, MapPin, Home } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { useFavorites } from '../hooks/useFavorites';
import { motion } from 'framer-motion';
import type { PetSitter } from '../types';
import { ANIMAL_LABELS } from '../utils/animalLabels';

interface PetSitterCardProps {
  sitter: PetSitter;
  onBooking: (sitter: PetSitter) => void;
  requireAuth?: boolean;
}

const MAX_VISIBLE_SERVICES = 2;
const MAX_VISIBLE_ANIMALS = 3;

export default function PetSitterCard({ sitter, onBooking, requireAuth = false }: PetSitterCardProps) {
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const { toggleFavorite, isFavorite, loading: favoritesLoading } = useFavorites(user?.uid);
  const [isLoading, setIsLoading] = useState(false);

  const displayName = sitter.businessName || sitter.name;
  const visibleServices = sitter.services.slice(0, MAX_VISIBLE_SERVICES);
  const remainingServices = sitter.services.length - MAX_VISIBLE_SERVICES;
  const visibleAnimals = sitter.animals.slice(0, MAX_VISIBLE_ANIMALS);
  const remainingAnimals = sitter.animals.length - MAX_VISIBLE_ANIMALS;

  const handleFavoriteClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!user) {
      navigate('/connexion');
      return;
    }

    if (userData?.role === 'sitter') {
      alert("En tant que pet sitter, vous ne pouvez pas ajouter de favoris.");
      return;
    }

    if (favoritesLoading || isLoading) return;

    try {
      setIsLoading(true);
      await toggleFavorite(sitter.id);
    } catch (error) {
      console.error('Erreur lors de la gestion des favoris:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = () => {
    navigate(`/petsitter/${sitter.id}`);
  };

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -4, transition: { duration: 0.2 } }}
      onClick={handleCardClick}
      className="bg-white rounded-xl shadow-md overflow-hidden transition-shadow hover:shadow-xl w-full flex flex-col h-full"
    >
      {/* Image et avatar */}
      <div className="relative h-40 sm:h-48 flex-shrink-0">
        {sitter.photos && sitter.photos.length > 0 ? (
          <img
            src={sitter.photos[0]}
            alt="Photo de couverture"
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full bg-gradient-to-br from-primary-400 to-primary-600" />
        )}
        
        <div className="absolute -bottom-6 left-4 w-16 sm:w-20 h-16 sm:h-20 rounded-full border-4 border-white shadow-lg overflow-hidden z-20 bg-white">
          <img
            src={sitter.avatar || `https://source.unsplash.com/100x100/?portrait&random=${sitter.id}`}
            alt={displayName}
            className="w-full h-full object-cover"
          />
        </div>

        {user && (
          <motion.button 
            onClick={handleFavoriteClick}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            disabled={favoritesLoading || isLoading}
            className={`absolute top-2 right-2 p-2 bg-white rounded-full shadow-md transition-colors z-20 ${
              isFavorite(sitter.id) 
                ? 'text-red-500 hover:text-red-600' 
                : 'text-gray-600 hover:text-gray-700'
            } ${(favoritesLoading || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <Heart 
              className={`w-5 h-5 ${isFavorite(sitter.id) ? 'fill-current' : ''}`} 
            />
          </motion.button>
        )}
      </div>
      
      {/* Contenu */}
      <div className="p-4 pt-8 flex flex-col flex-grow">
        <div className="flex justify-between items-start mb-3">
          <div>
            <h3 className="text-base sm:text-lg font-semibold text-gray-900">{displayName}</h3>
            <div className="flex items-center text-xs sm:text-sm text-gray-600 mt-1">
              <MapPin className="w-4 h-4 mr-1" />
              <span>{sitter.location}</span>
            </div>
          </div>
          <div className="flex items-center">
            <Star className="w-4 h-4 sm:w-5 sm:h-5 text-yellow-400 fill-current" />
            <span className="ml-1 font-medium text-sm sm:text-base">{sitter.rating.toFixed(1)}</span>
          </div>
        </div>

        <div className="flex flex-wrap gap-1.5 sm:gap-2 mb-3">
          {visibleAnimals.map((animal) => (
            <span
              key={animal}
              className="px-2 py-0.5 bg-primary-100 text-primary-700 rounded-full text-xs"
            >
              {ANIMAL_LABELS[animal] || animal}
            </span>
          ))}
          {remainingAnimals > 0 && (
            <span className="px-2 py-0.5 bg-gray-100 text-gray-600 rounded-full text-xs">
              +{remainingAnimals} autre{remainingAnimals > 1 ? 's' : ''}
            </span>
          )}
        </div>

        <div className="space-y-2 mb-3">
          {visibleServices.map((service) => (
            <div 
              key={service.type}
              className="flex justify-between items-center"
            >
              <span className="text-xs sm:text-sm text-gray-600 truncate max-w-[70%]">{service.description}</span>
              <span className="font-medium text-xs sm:text-sm">{service.price}€/jour</span>
            </div>
          ))}
          {remainingServices > 0 && (
            <div className="text-xs sm:text-sm text-primary-500 font-medium">
              +{remainingServices} autre{remainingServices > 1 ? 's' : ''} service{remainingServices > 1 ? 's' : ''}
            </div>
          )}
        </div>

        {sitter.specificities?.housingType && (
          <div className="flex items-center text-xs sm:text-sm text-gray-600 mb-3">
            <Home className="w-4 h-4 mr-2 text-primary-500" />
            <span>{sitter.specificities.housingType}</span>
          </div>
        )}

        {/* Bouton toujours en bas */}
        <div className="mt-auto pt-3">
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/petsitter/${sitter.id}`);
            }}
            className="w-full bg-primary-500 text-white px-4 py-2.5 sm:py-3 rounded-lg hover:bg-primary-600 transition text-xs sm:text-sm font-medium"
          >
            Voir le profil
          </button>
        </div>
      </div>
    </motion.div>
  );
}