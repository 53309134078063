import React, { useState, useEffect } from 'react';
import { Star, MapPin, Home, Users, TreePine, GraduationCap } from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import type { PetSitter } from '../types';
import ReviewSection from '../components/ReviewSection';
import ReservationModal from '../components/ReservationModal';
import PhotoGallerySlider from '../components/PhotoGallerySlider';
import { HOUSING_LABELS, CHILDREN_LABELS, WALKING_AREAS_LABELS, EXPERIENCE_LABELS, YEARS_OF_EXPERIENCE_LABELS } from '../utils/labels';
import { ANIMAL_LABELS } from '../utils/animalLabels';

export default function PetSitterDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, userData } = useAuth();
  const [sitter, setSitter] = useState<PetSitter | null>(null);
  const [loading, setLoading] = useState(true);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);

  useEffect(() => {
    loadSitterProfile();
  }, [id]);

  const loadSitterProfile = async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      const docRef = doc(db, 'sitterProfiles', id);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        setSitter({ id: docSnap.id, ...docSnap.data() } as PetSitter);
      }
    } catch (error) {
      console.error('Erreur lors du chargement du profil:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReservationClick = () => {
    if (!user) {
      navigate('/connexion', { state: { from: `/petsitter/${id}` } });
      return;
    }

    if (userData?.role === 'sitter') {
      alert("En tant que PetSitter, vous ne pouvez pas réserver de services.");
      return;
    }

    setIsReservationModalOpen(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8 flex items-center justify-center">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-48 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
    );
  }

  if (!sitter) {
    return (
      <div className="min-h-screen bg-gray-50 py-8 flex items-center justify-center">
        <p className="text-gray-500">PetSitter non trouvé</p>
      </div>
    );
  }

  const displayName = sitter.businessName || sitter.name;

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-lg overflow-hidden">
          {/* Photo Gallery */}
          {sitter.photos && sitter.photos.length > 0 && (
            <PhotoGallerySlider photos={sitter.photos} />
          )}

          {/* Profile Content */}
          <div className="p-8">
            <div className="flex justify-between items-start mb-6">
              <div className="flex items-center">
                <div className="w-20 h-20 rounded-full overflow-hidden border-4 border-white shadow-lg mr-4">
                  <img
                    src={sitter.avatar || `https://source.unsplash.com/400x400/?portrait&random=${sitter.id}`}
                    alt={displayName}
                    className="w-full h-full object-cover"
                  />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">{displayName}</h1>
                  <div className="flex items-center mt-2 text-gray-600">
                    <MapPin className="w-4 h-4 mr-1" />
                    <span>{sitter.location}</span>
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <Star className="w-6 h-6 text-yellow-400 fill-current" />
                <span className="ml-1 font-medium text-lg">{sitter.rating.toFixed(1)}</span>
              </div>
            </div>

            <div className="prose max-w-none mb-8">
              <p className="text-gray-600">{sitter.description}</p>
            </div>

            {/* Types d'animaux acceptés */}
            <div className="mb-8">
              <h3 className="font-medium mb-4">Animaux acceptés</h3>
              <div className="flex flex-wrap gap-2">
                {sitter.animals.map((animal) => (
                  <span
                    key={animal}
                    className="px-3 py-1 bg-primary-100 text-primary-700 rounded-full text-sm"
                  >
                    {ANIMAL_LABELS[animal] || animal}
                  </span>
                ))}
              </div>
            </div>

            {/* Services proposés */}
            <div className="border-t pt-6">
              <h3 className="font-medium mb-4">Services proposés</h3>
              <div className="space-y-3">
                {sitter.services.map((service) => (
                  <div 
                    key={service.type}
                    className="flex flex-col p-4 bg-gray-50 rounded-lg"
                  >
                    <div className="flex justify-between items-start mb-2">
                      <span className="font-medium">{service.description}</span>
                      <div className="text-right">
                        <div className="font-medium">{service.price}€/jour</div>
                        {service.priceWeek && (
                          <div className="text-sm text-gray-600 mt-1">
                            {service.priceWeek}€/semaine
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Section des avis */}
            <div className="border-t pt-6 mt-8">
              <ReviewSection 
                sitterId={sitter.id}
                onReviewAdded={loadSitterProfile}
              />
            </div>

            {/* Bouton de réservation */}
            <div className="mt-8">
              <button
                onClick={handleReservationClick}
                className="w-full max-w-sm mx-auto block bg-primary-500 text-white px-6 py-3 rounded-lg hover:bg-primary-600 transition"
              >
                Réserver
              </button>
            </div>
          </div>
        </div>

        {/* Modal de réservation */}
        {sitter && (
          <ReservationModal
            isOpen={isReservationModalOpen}
            onClose={() => setIsReservationModalOpen(false)}
            sitter={sitter}
          />
        )}
      </div>
    </div>
  );
}