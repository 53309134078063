export const ROUTES = {
  // Routes publiques
  HOME: '/',
  SEARCH: '/recherche',
  ABOUT: '/a-propos',
  HOW_IT_WORKS: '/comment-ca-marche',
  AUTH: '/connexion',
  SUBSCRIPTION: '/inscription',
  BLOG: '/blog',
  BLOG_POST: '/blog/:slug',
  CONTACT: '/contact',
  COOKIES: '/cookies',
  TERMS: '/conditions-generales',
  COORDINATES: '/coordonnees',
  
  // Routes PetSitter
  PETSITTER_DASHBOARD: '/petsitter/tableau-de-bord',
  PETSITTER_PROFILE: '/petsitter/profil',
  PETSITTER_SETTINGS: '/petsitter/parametres',
  PETSITTER_DETAILS: '/petsitter/:id',
  
  // Routes Propriétaire
  OWNER_BOOKINGS: '/proprietaire/reservations',
  OWNER_FAVORITES: '/proprietaire/favoris'
} as const;