import { doc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export async function loadSuspensionState(userId: string) {
  try {
    const sitterDoc = await getDoc(doc(db, 'sitterProfiles', userId));
    if (sitterDoc.exists()) {
      return sitterDoc.data().suspended || false;
    }
    return false;
  } catch (err) {
    console.error('Erreur lors du chargement de l\'état de suspension:', err);
    return false;
  }
}