import React, { createContext, useState, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
  User
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import { addMonths, format, isAfter } from 'date-fns';

interface UserData {
  id: string;
  email: string;
  role: 'owner' | 'sitter';
  name?: string;
  trialEndDate?: string;
  subscriptionStatus?: 'trial' | 'active' | 'expired';
  subscriptionEndDate?: string;
  suspended?: boolean;
  profile?: any;
}

interface AuthContextType {
  user: User | null;
  userData: UserData | null;
  loading: boolean;
  error: string | null;
  initialized: boolean;
  signup: (email: string, password: string, role: 'owner' | 'sitter', name: string) => Promise<User>;
  login: (email: string, password: string) => Promise<User>;
  logout: () => Promise<void>;
  checkSubscriptionStatus: () => boolean;
  updateUserProfile: (profileData: any) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (!user) {
        setUserData(null);
        setLoading(false);
        setInitialized(true);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    let unsubscribeUser: (() => void) | undefined;

    if (user) {
      const userRef = doc(db, 'users', user.uid);
      unsubscribeUser = onSnapshot(userRef, async (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          
          if (data.role === 'sitter' && data.subscriptionStatus === 'trial') {
            const trialEnd = new Date(data.trialEndDate);
            if (isAfter(new Date(), trialEnd)) {
              await updateDoc(userRef, {
                subscriptionStatus: 'expired'
              });
              
              await updateDoc(doc(db, 'sitterProfiles', user.uid), {
                suspended: true
              });

              data.subscriptionStatus = 'expired';
              data.suspended = true;
            }
          }

          setUserData({ id: user.uid, ...data } as UserData);
        }
        setLoading(false);
        setInitialized(true);
      });
    }

    return () => {
      if (unsubscribeUser) {
        unsubscribeUser();
      }
    };
  }, [user]);

  const signup = async (email: string, password: string, role: 'owner' | 'sitter', name: string) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await updateProfile(user, { displayName: name });

      const trialEndDate = addMonths(new Date(), 3);
      const userData = {
        email,
        role,
        name,
        createdAt: new Date().toISOString(),
        trialEndDate: format(trialEndDate, 'yyyy-MM-dd'),
        subscriptionStatus: role === 'sitter' ? 'trial' : null,
        suspended: false
      };

      await setDoc(doc(db, 'users', user.uid), userData);

      if (role === 'sitter') {
        await setDoc(doc(db, 'sitterProfiles', user.uid), {
          name,
          email,
          description: '',
          location: '',
          services: [],
          animals: [],
          rating: 0,
          reviews: [],
          suspended: false,
          createdAt: new Date().toISOString()
        });

        await setDoc(doc(db, 'notifications', `welcome_${user.uid}`), {
          userId: user.uid,
          type: 'welcome',
          title: 'Bienvenue sur PetSitter !',
          message: `Votre période d'essai de 3 mois commence aujourd'hui et se terminera le ${format(trialEndDate, 'dd/MM/yyyy')}. Profitez-en pour compléter votre profil et commencer à recevoir des réservations !`,
          read: false,
          createdAt: new Date(),
          data: {
            trialEndDate: format(trialEndDate, 'yyyy-MM-dd')
          }
        });
      }

      return user;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error during signup');
      throw err;
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      return result.user;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error during login');
      throw err;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Error during logout');
      throw err;
    }
  };

  const checkSubscriptionStatus = () => {
    if (!userData || userData.role !== 'sitter') return true;

    if (userData.subscriptionStatus === 'active') {
      return !userData.suspended && (!userData.subscriptionEndDate || !isAfter(new Date(), new Date(userData.subscriptionEndDate)));
    }

    if (userData.subscriptionStatus === 'trial') {
      return !userData.suspended && !isAfter(new Date(), new Date(userData.trialEndDate));
    }

    return false;
  };

  const updateUserProfile = async (profileData: any) => {
    if (!user) return;

    try {
      const userRef = doc(db, 'sitterProfiles', user.uid);
      await updateDoc(userRef, profileData);

      localStorage.setItem(`profile-data-${user.uid}`, JSON.stringify(profileData));
      setUserData(prev => prev ? { ...prev, profile: profileData } : null);
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
      throw error;
    }
  };

  const value = {
    user,
    userData,
    loading,
    error,
    initialized,
    signup,
    login,
    logout,
    checkSubscriptionStatus,
    updateUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}