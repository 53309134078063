import React from 'react';
import { Calendar, MapPin, Clock, Star } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface ReservationCardProps {
  reservation: any;
  onAccept?: (id: string) => void;
  onReject?: (id: string) => void;
  onViewDetails?: (reservation: any) => void;
  showReviewButton?: boolean;
  onReviewClick?: () => void;
}

export default function ReservationCard({
  reservation,
  onAccept,
  onReject,
  onViewDetails,
  showReviewButton,
  onReviewClick
}: ReservationCardProps) {
  const startDate = format(new Date(reservation.startDate), 'dd MMMM yyyy', { locale: fr });
  const endDate = format(new Date(reservation.endDate), 'dd MMMM yyyy', { locale: fr });

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'text-green-500';
      case 'pending':
        return 'text-orange-500';
      case 'rejected':
        return 'text-red-500';
      default:
        return 'text-gray-500';
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case 'confirmed':
        return 'Confirmée';
      case 'pending':
        return 'En attente';
      case 'rejected':
        return 'Refusée';
      default:
        return status;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="font-semibold text-lg">{reservation.ownerName}</h3>
          <div className="space-y-2 text-sm text-gray-600">
            <div className="flex items-center">
              <Calendar className="w-4 h-4 mr-2" />
              <span>Du {startDate} au {endDate}</span>
            </div>
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2" />
              <span>Service : {reservation.serviceType}</span>
            </div>
            {reservation.petInfo && (
              <div className="flex items-center">
                <Star className="w-4 h-4 mr-2" />
                <span>Animal : {reservation.petInfo.name} ({reservation.petInfo.type})</span>
              </div>
            )}
          </div>
        </div>

        <div className="text-right">
          <div className="font-semibold text-lg text-primary-500">
            {reservation.priceToDiscuss ? 'Prix à discuter' : `${reservation.totalPrice}€`}
          </div>
          <div className="text-sm text-gray-500">
            {reservation.duration} jour{reservation.duration > 1 ? 's' : ''}
          </div>
          <div className={`mt-2 text-sm ${getStatusColor(reservation.status)}`}>
            {getStatusText(reservation.status)}
          </div>
        </div>
      </div>

      <div className="border-t pt-4 flex justify-end space-x-4">
        {onViewDetails && (
          <button
            onClick={() => onViewDetails(reservation)}
            className="px-4 py-2 text-primary-500 hover:text-primary-600"
          >
            Voir détails
          </button>
        )}
        {onReject && (
          <button
            onClick={() => onReject(reservation.id)}
            className="px-4 py-2 border border-red-500 text-red-500 rounded-lg hover:bg-red-50"
          >
            Refuser
          </button>
        )}
        {onAccept && (
          <button
            onClick={() => onAccept(reservation.id)}
            className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
          >
            Accepter
          </button>
        )}
        {showReviewButton && onReviewClick && !reservation.hasReview && (
          <button
            onClick={onReviewClick}
            className="px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-600"
          >
            Laisser un avis
          </button>
        )}
      </div>
    </div>
  );
}