import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useReservations } from '../../hooks/useReservations';
import ReservationCard from '../../components/ReservationCard';
import ReservationDetailsModal from '../../components/ReservationDetailsModal';
import SubscriptionAlert from '../../components/SubscriptionAlert';

export default function PetSitterDashboard() {
  const navigate = useNavigate();
  const { user, userData, loading, initialized, checkSubscriptionStatus } = useAuth();
  const { reservations, loading: reservationsLoading, updateReservationStatus } = useReservations(user?.uid);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [activeReservations, setActiveReservations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  useEffect(() => {
    if (!loading && initialized) {
      if (!user) {
        navigate('/connexion');
        return;
      }

      if (userData?.role !== 'sitter') {
        navigate('/');
        return;
      }

      if (!checkSubscriptionStatus()) {
        navigate('/inscription');
        return;
      }
    }
  }, [user, userData, loading, initialized, navigate, checkSubscriptionStatus]);

  useEffect(() => {
    if (reservations) {
      setPendingReservations(reservations.filter(r => r.status === 'pending'));
      setActiveReservations(reservations.filter(r => r.status === 'confirmed'));
    }
  }, [reservations]);

  const handleAccept = async (reservationId: string) => {
    try {
      await updateReservationStatus(reservationId, 'confirmed');
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de la réservation:', error);
    }
  };

  const handleReject = async (reservationId: string) => {
    try {
      await updateReservationStatus(reservationId, 'rejected');
    } catch (error) {
      console.error('Erreur lors du refus de la réservation:', error);
    }
  };

  const handleViewDetails = (reservation: any) => {
    setSelectedReservation(reservation);
    setIsDetailsModalOpen(true);
  };

  if (loading || !initialized) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-32 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <SubscriptionAlert />
        
        <h1 className="text-2xl font-bold mb-8">Tableau des réservations</h1>

        {/* Réservations en attente */}
        <section className="mb-12">
          <h2 className="text-xl font-semibold mb-4">
            Demandes de réservation en attente ({pendingReservations.length})
          </h2>
          {pendingReservations.length === 0 ? (
            <div className="bg-white rounded-lg shadow-md p-6 text-center text-gray-500">
              Aucune demande de réservation en attente
            </div>
          ) : (
            <div className="space-y-4">
              {pendingReservations.map((reservation) => (
                <ReservationCard
                  key={reservation.id}
                  reservation={reservation}
                  onAccept={handleAccept}
                  onReject={handleReject}
                  onViewDetails={handleViewDetails}
                />
              ))}
            </div>
          )}
        </section>

        {/* Réservations actives */}
        <section>
          <h2 className="text-xl font-semibold mb-4">
            Réservations confirmées ({activeReservations.length})
          </h2>
          {activeReservations.length === 0 ? (
            <div className="bg-white rounded-lg shadow-md p-6 text-center text-gray-500">
              Aucune réservation confirmée
            </div>
          ) : (
            <div className="space-y-4">
              {activeReservations.map((reservation) => (
                <ReservationCard
                  key={reservation.id}
                  reservation={reservation}
                  onViewDetails={handleViewDetails}
                />
              ))}
            </div>
          )}
        </section>

        {/* Modal de détails */}
        {selectedReservation && (
          <ReservationDetailsModal
            isOpen={isDetailsModalOpen}
            onClose={() => setIsDetailsModalOpen(false)}
            reservation={selectedReservation}
          />
        )}
      </div>
    </div>
  );
}