export const SERVICE_OPTIONS = {
  // Options pour les chiens
  'ext-espace-privatif': 'En extérieur dans un espace privatif clos (chenil)',
  'partage-sans-acces': "Partage l'espace de vie sans accès à un espace extérieur clos",
  'partage-avec-acces': "Partage l'espace de vie avec accès à un espace clos extérieur (cour ou jardin)",
  'int-sans-acces': 'En intérieur dans une pièce dédiée sans accès à un espace extérieur clos',
  'int-avec-acces': 'En intérieur dans une pièce dédiée avec accès à un espace extérieur clos (cour ou jardin)',
  
  // Options pour les visites chiens
  '1-visite': '1x visite à domicile par jour + nourrissage',
  '2-visites': '2x visite à domicile par jour + nourrissage',
  '1-visite-1-promenade': '1x visite à domicile par jour + 1x promenade du chien + nourrissage',
  '2-visites-2-promenades': '2x visites à domicile par jour + 2x promenade du chien + nourrissage',
  
  // Options pour les promenades
  'promenade-1x': 'Promenade du chien 1x par jour',
  'promenade-2x': 'Promenade du chien 2x par jour',
  
  // Options pour les chats
  'chat-partage-sans-acces': "Partage l'espace de vie sans accès à un espace extérieur clos",
  'chat-partage-avec-acces': "Partage l'espace de vie avec accès à un espace clos extérieur (cour ou jardin)",
  'chat-int-sans-acces': 'En intérieur dans une pièce dédiée sans accès à un espace extérieur clos',
  'chat-int-avec-acces': 'En intérieur dans une pièce dédiée avec accès à un espace extérieur clos (cour ou jardin)',
  
  // Options pour les visites chats
  'chat-1-visite': '1x visite à domicile par jour + nourrissage',
  'chat-2-visites': '2x visites à domicile par jour + nourrissage'
};