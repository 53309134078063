import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { MoveHorizontal } from 'lucide-react';
import PetSitterCard from './PetSitterCard';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import type { PetSitter } from '../types';

export default function FeaturedSitters() {
  const navigate = useNavigate();
  const [sitters, setSitters] = useState<PetSitter[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadSitters();
  }, []);

  const loadSitters = async () => {
    try {
      const q = query(
        collection(db, 'sitterProfiles'),
        where('suspended', '==', false)
      );
      
      const querySnapshot = await getDocs(q);
      const sittersData: PetSitter[] = [];
      
      querySnapshot.forEach((doc) => {
        const sitterData = doc.data();
        if (sitterData.services?.length > 0) {
          sittersData.push({
            id: doc.id,
            ...sitterData
          } as PetSitter);
        }
      });

      setSitters(sittersData);
    } catch (error) {
      console.error('Erreur lors du chargement des pet sitters:', error);
      setSitters([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-white rounded-xl shadow-lg p-6 h-[520px] animate-pulse">
            <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
            <div className="space-y-3">
              <div className="h-6 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2"></div>
              <div className="h-4 bg-gray-200 rounded w-2/3"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (sitters.length === 0) {
    return null;
  }

  return (
    <>
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-center mb-8"
      >
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-3">
          Découvrez nos derniers PetSitters affiliés
        </h2>
      </motion.div>

      {/* Indicateur de défilement sur mobile */}
      <div className="flex items-center justify-center mb-4 sm:hidden text-gray-500">
        <MoveHorizontal className="w-5 h-5 mr-2" />
        <span className="text-sm">Faites défiler pour voir plus</span>
      </div>

      {/* Container avec défilement horizontal sur mobile */}
      <div className="flex sm:grid sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8 overflow-x-auto snap-x snap-mandatory scrollbar-hide -mx-4 px-4 sm:mx-0 sm:px-0">
        {sitters.map((sitter, index) => (
          <motion.div
            key={sitter.id}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="w-[85vw] flex-shrink-0 snap-center sm:w-auto"
          >
            <PetSitterCard
              sitter={sitter}
              onBooking={() => navigate(`/petsitter/${sitter.id}`)}
            />
          </motion.div>
        ))}
      </div>
    </>
  );
}