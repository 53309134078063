import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../routes/constants';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo et Description */}
          <div className="col-span-1 md:col-span-2">
            <Link to={ROUTES.HOME} className="flex items-center mb-6">
              <img 
                src="https://firebasestorage.googleapis.com/v0/b/europetsitter-fd612.firebasestorage.app/o/logo%20Europetsitter.avif?alt=media&token=939040f2-c959-4b6b-8484-28128a62b145"
                alt="EuroPetSitter" 
                className="h-16 w-auto"
              />
            </Link>
            <p className="text-gray-400 mb-6">
              Trouvez le PetSitter idéal pour votre animal de compagnie. Notre plateforme vous connecte avec des PetSitters compétents.
            </p>
          </div>

          {/* Navigation */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Navigation</h3>
            <ul className="space-y-2">
              <li>
                <Link to={ROUTES.SEARCH} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Rechercher un PetSitter
                </Link>
              </li>
              <li>
                <Link to={ROUTES.ABOUT} className="text-gray-400 hover:text-primary-500 transition-colors">
                  À propos
                </Link>
              </li>
              <li>
                <Link to={ROUTES.HOW_IT_WORKS} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Comment ça marche
                </Link>
              </li>
              <li>
                <Link to={ROUTES.BLOG} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to={ROUTES.CONTACT} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          {/* Légal */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Mentions légales</h3>
            <ul className="space-y-2">
              <li>
                <Link to={ROUTES.COORDINATES} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Coordonnées
                </Link>
              </li>
              <li>
                <Link to={ROUTES.TERMS} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Conditions générales
                </Link>
              </li>
              <li>
                <Link to={ROUTES.COOKIES} className="text-gray-400 hover:text-primary-500 transition-colors">
                  Politique de confidentialité
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8 text-center">
          <p className="text-gray-400">
            © {new Date().getFullYear()} EuroPetSitter.fr Tous droits réservés.
          </p>
        </div>
      </div>
    </footer>
  );
}