import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useReservations } from '../../hooks/useReservations';
import ReservationCard from '../../components/ReservationCard';
import ReviewModal from '../../components/ReviewModal';

export default function OwnerDashboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { reservations, loading } = useReservations(undefined, user?.uid);
  const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>('upcoming');
  const [selectedReservation, setSelectedReservation] = useState<any>(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const filterReservations = () => {
    const now = new Date();
    return reservations.filter(reservation => {
      const endDate = new Date(reservation.endDate);
      return activeTab === 'upcoming' ? endDate >= now : endDate < now;
    });
  };

  const handleReviewClick = (reservation: any) => {
    setSelectedReservation(reservation);
    setIsReviewModalOpen(true);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/4 mb-6"></div>
            <div className="space-y-4">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-32 bg-gray-200 rounded"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-4xl mx-auto px-4">
        <h1 className="text-2xl font-bold mb-8">Mes réservations</h1>

        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab('upcoming')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'upcoming'
                ? 'bg-primary-500 text-white'
                : 'bg-white text-gray-600 hover:bg-gray-50'
            }`}
          >
            À venir
          </button>
          <button
            onClick={() => setActiveTab('past')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'past'
                ? 'bg-primary-500 text-white'
                : 'bg-white text-gray-600 hover:bg-gray-50'
            }`}
          >
            Passées
          </button>
        </div>

        <div className="space-y-4">
          {filterReservations().map((reservation) => (
            <ReservationCard
              key={reservation.id}
              reservation={reservation}
              showReviewButton={activeTab === 'past' && reservation.status === 'confirmed'}
              onReviewClick={() => handleReviewClick(reservation)}
            />
          ))}

          {filterReservations().length === 0 && (
            <div className="text-center py-12 bg-white rounded-lg shadow">
              <p className="text-gray-500 mb-4">
                Aucune réservation {activeTab === 'upcoming' ? 'à venir' : 'passée'}
              </p>
              <button
                onClick={() => navigate('/recherche')}
                className="text-primary-500 hover:text-primary-600 font-medium"
              >
                Rechercher un PetSitter
              </button>
            </div>
          )}
        </div>

        {selectedReservation && (
          <ReviewModal
            isOpen={isReviewModalOpen}
            onClose={() => setIsReviewModalOpen(false)}
            sitterId={selectedReservation.sitterId}
            sitterName={selectedReservation.sitterName}
            reservationId={selectedReservation.id}
          />
        )}
      </div>
    </div>
  );
}