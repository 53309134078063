import React from 'react';
import type { PetSitter, Service } from '../types';
import type { PetData } from './PetForm';
import PetForm from './PetForm';
import Calendar from './Calendar';
import { SERVICE_OPTIONS } from '../data/serviceOptions';

interface ReservationStepsProps {
  currentStep: string;
  petData: PetData | null;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  onPetSubmit: (data: PetData) => void;
  selectedService: Service | null;
  setSelectedService: (service: Service | null) => void;
  setCurrentStep: (step: string) => void;
  sitter: PetSitter;
  startDate: string;
  endDate: string;
  onDateSelect: (date: Date) => void;
  formatPriceDetails: () => string;
  getDuration: () => number;
  isPriceToDiscuss: () => boolean;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
}

export default function ReservationSteps({
  currentStep,
  petData,
  phoneNumber,
  setPhoneNumber,
  onPetSubmit,
  selectedService,
  setSelectedService,
  setCurrentStep,
  sitter,
  startDate,
  endDate,
  onDateSelect,
  formatPriceDetails,
  getDuration,
  isPriceToDiscuss,
  selectedOption,
  setSelectedOption
}: ReservationStepsProps) {
  const getServiceOptions = () => {
    if (!petData || !selectedService) return [];

    const isDog = petData.type.includes('chien');
    const isCat = petData.type === 'chat';

    if (isDog) {
      switch (selectedService.type) {
        case 'hebergement':
        case 'garde':
          return sitter.serviceOptions?.hebergementChien || [];
        case 'visite':
          return sitter.serviceOptions?.visiteChien || [];
        case 'promenade':
          return sitter.serviceOptions?.promenadeChien || [];
        default:
          return [];
      }
    }

    if (isCat) {
      switch (selectedService.type) {
        case 'hebergement':
        case 'garde':
          return sitter.serviceOptions?.hebergementChat || [];
        case 'visite':
          return sitter.serviceOptions?.visiteChat || [];
        default:
          return [];
      }
    }

    return [];
  };

  const serviceOptions = getServiceOptions();

  if (currentStep === 'pet') {
    return (
      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Votre numéro de téléphone
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-primary-200 focus:border-primary-500"
            placeholder="Ex: 06 12 34 56 78"
            required
          />
        </div>
        <PetForm
          onSubmit={onPetSubmit}
          availableAnimals={sitter.animals}
        />
      </div>
    );
  }

  if (currentStep === 'service') {
    return (
      <div className="space-y-6">
        <h3 className="font-medium mb-4">Sélectionnez un service</h3>
        <div className="space-y-3">
          {sitter.services.map((service) => (
            <button
              key={service.type}
              onClick={() => {
                setSelectedService(service);
                setSelectedOption('');
                setCurrentStep('dates');
              }}
              className={`w-full p-4 text-left border rounded-lg transition-colors ${
                selectedService?.type === service.type
                  ? 'border-primary-500 bg-primary-50'
                  : 'hover:bg-gray-50'
              }`}
            >
              <div className="font-medium">{service.description}</div>
              <div className="text-sm text-gray-600 mt-1">
                {service.price}€/jour
                {service.priceWeek && ` • ${service.priceWeek}€/semaine`}
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }

  if (currentStep === 'dates') {
    return (
      <div className="space-y-6">
        <div className="bg-primary-50 rounded-lg p-4">
          <h3 className="font-medium mb-2">Récapitulatif</h3>
          <div className="space-y-2 text-sm">
            <p><span className="font-medium">Animal :</span> {petData?.name} ({petData?.type})</p>
            <p><span className="font-medium">Service :</span> {selectedService?.description}</p>
            <p><span className="font-medium">Prix :</span> {selectedService?.price}€/jour</p>
          </div>
        </div>

        {serviceOptions.length > 0 && (
          <div>
            <h3 className="font-medium mb-4">Options disponibles</h3>
            <div className="space-y-3">
              {serviceOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => setSelectedOption(option)}
                  className={`w-full p-4 text-left border rounded-lg transition-colors ${
                    selectedOption === option
                      ? 'border-primary-500 bg-primary-50'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <div className="text-sm">{SERVICE_OPTIONS[option]}</div>
                </button>
              ))}
            </div>
          </div>
        )}

        <div>
          <h3 className="font-medium mb-4">Sélectionnez vos dates</h3>
          <Calendar
            sitterId={sitter.id}
            onDateSelect={onDateSelect}
            selectedDate={startDate}
            endDate={endDate}
          />
        </div>

        {startDate && endDate && (
          <div className="bg-gray-50 p-4 rounded-lg">
            <div className="space-y-2">
              <div className="text-sm text-gray-600">{formatPriceDetails()}</div>
              <div className="flex justify-between items-center">
                <span>Total estimé :</span>
                <span className="font-bold text-lg">
                  {isPriceToDiscuss() ? 'Prix à discuter' : `${selectedService?.price * getDuration()}€`}
                </span>
              </div>
            </div>
            <button
              onClick={() => setCurrentStep('confirmation')}
              disabled={serviceOptions.length > 0 && !selectedOption}
              className="w-full mt-4 bg-primary-500 text-white px-6 py-2 rounded-lg hover:bg-primary-600 transition disabled:opacity-50"
            >
              Continuer
            </button>
          </div>
        )}
      </div>
    );
  }

  return null;
}